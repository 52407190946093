// The ProductType enum is essentially two distinct enums that overlap:
// - BaseProductType: Represents product types used in backend responses.
// - AppContextProductType: Similar to ProductType, but excludes ProductType.Diamond.
// In the new code types, one of these enums should be used instead of ProductType.
// Using ProductType.Diamond in the app context will result in an error.
// Similarly, using ProductType.DiamondColorless where BaseProductType (e.g., ProductType.Diamond) is expected will also cause an error.
export var ProductType;
(function (ProductType) {
    ProductType["Diamond"] = "diamond";
    ProductType["DiamondColorless"] = "diamond-colorless";
    ProductType["DiamondFancy"] = "diamond-fancy";
    ProductType["LGD"] = "lgd";
    ProductType["LGDColorless"] = "lgd-colorless";
    ProductType["LGDFancy"] = "lgd-fancy";
    ProductType["Rough"] = "rough";
    ProductType["LGDRough"] = "lgd-rough";
    ProductType["Jewelry"] = "jewelry";
})(ProductType || (ProductType = {}));
export const TYPE_DIAMOND = ProductType.Diamond;
export const TYPE_DIAMOND_COLORLESS = ProductType.DiamondColorless;
export const TYPE_DIAMOND_FANCY = ProductType.DiamondFancy;
export const TYPE_LGD = ProductType.LGD;
export const TYPE_LGD_COLORLESS = ProductType.LGDColorless;
export const TYPE_LGD_FANCY = ProductType.LGDFancy;
export const TYPE_ROUGH = ProductType.Rough;
export const TYPE_LGD_ROUGH = ProductType.LGDRough;
export const TYPE_JEWELRY = ProductType.Jewelry;
export const COMPARE_DIAMOND_GROUP = new Set([
    ProductType.DiamondColorless,
    ProductType.DiamondFancy,
    ProductType.LGDColorless,
    ProductType.LGDFancy,
]);
export const COMPARE_ROUGH_GROUP = new Set([ProductType.Rough, ProductType.LGDRough]);
export const ALL_PRODUCT_TYPES = [
    ProductType.Diamond,
    ProductType.DiamondColorless,
    ProductType.DiamondFancy,
    ProductType.LGD,
    ProductType.LGDColorless,
    ProductType.LGDFancy,
    ProductType.LGDRough,
    ProductType.Jewelry,
    ProductType.Rough,
];
export function reverseProductType(productType) {
    switch (productType) {
        case ProductType.DiamondColorless:
            return ProductType.LGDColorless;
        case ProductType.DiamondFancy:
            return ProductType.LGDFancy;
        case ProductType.Rough:
            return ProductType.LGDRough;
        case ProductType.LGDColorless:
            return ProductType.DiamondColorless;
        case ProductType.LGDFancy:
            return ProductType.DiamondFancy;
        case ProductType.LGDRough:
            return ProductType.Rough;
        default:
            return productType;
    }
}
export function isFancyDiamond(productType) {
    return productType === ProductType.DiamondFancy || productType === ProductType.LGDFancy;
}
export function isColorlessDiamond(productType) {
    return productType === ProductType.DiamondColorless || productType === ProductType.LGDColorless;
}
export function isDiamond(productType) {
    return productType === ProductType.Diamond || isFancyDiamond(productType) || isColorlessDiamond(productType);
}
export function isRough(productType) {
    return productType === ProductType.Rough || productType === ProductType.LGDRough;
}
export function baseProductTypeToProductType(baseType, isLabGrown) {
    if (!isLabGrown) {
        return baseType;
    }
    switch (baseType) {
        case ProductType.Diamond:
            return ProductType.LGD;
        case ProductType.Rough:
            return ProductType.LGDRough;
        case ProductType.Jewelry:
            return ProductType.Jewelry;
    }
}
